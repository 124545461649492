import React from "react";
import { masterContent1 } from "../../../constants/masterContent";
import ImageSection from "../ImageSection/ImageSection";
import MasterBanner from "../MasterBanner/MasterBanner";
import * as styles from "./WizrConnect.module.scss";
function WizrConnectModule() {
  return (
    <div className={styles.container}>
      <MasterBanner
        extraClass="wizrmodule"
        title="WiZR Connect"
        span="Snapshots"
        description={
          "WiZR Connect offers more than just chat, video call, and screen sharing features. Explore its additional capabilities below. "
        }
      />
      <div style={{ color: "white " }} className={styles.sections}>
        {masterContent1.map((d, i) => {
          return <ImageSection extraClass="imageWizr" {...d} key={i} />;
        })}
      </div>
    </div>
  );
}

export default WizrConnectModule;
